/**
 * Collection of utility functions
 *
 *
 */

const FormatTitleCase = str => {
  const cleaned = str.replace(/-/g, " "); // remove hyphen
  return cleaned.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

const ClosestNumber = (array, value) =>
  array.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );

const ClosestLowerNumber = (array, value) => {
  let i = 0;

  while (array[++i] <= value);

  return array[--i];
};

const ConvertToRange = (value, srcRange, dstRange) => {
  if (value < srcRange[0]) {
    return dstRange[0];
  }
  if (value > srcRange[1]) {
    return dstRange[1];
  }
  const srcMax = srcRange[1] - srcRange[0];
  const dstMax = dstRange[1] - dstRange[0];
  const adjValue = value - srcRange[0];
  return (adjValue * dstMax) / srcMax + dstRange[0];
};

// debounce limits rate function can fire
//
// https://gist.github.com/nmsdvid/8807205
const Debounce = (callback, time = 250, interval) => (...args) => {
  clearTimeout(interval);
  interval = setTimeout(() => callback(...args), time);
};

const IsNumeric = n =>
  !Number.isNaN(parseFloat(n)) && Number.isFinite(n) && typeof n === "number";

const GetRandomInt = (min, max) => {
  const min2 = Math.ceil(min);
  const max2 = Math.floor(max);
  return Math.floor(Math.random() * (max2 - min2 + 1)) + min2;
};

const Between = (value, a, b, inclusive) => {
  const min = Math.min(a, b);
  const max = Math.max(a, b);

  return inclusive ? value >= min && value <= max : value > min && value < max;
};

const HasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className);
  }
  return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
};

const RemoveSpaces = str => str.replace(/ /g, "");

// https://blog.codinghorror.com/the-danger-of-naivete/
// knuth-fisher-yates shuffle
const Shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

const ArrayRotate = (arr, count) => {
  count -= arr.length * Math.floor(count / arr.length);
  arr.push.apply(arr, arr.splice(0, count));
  return arr;
};

const ParsePixels = val => {
  const unit = val.slice(-2);
  if (unit === "px") {
    const copiedPx = val.slice(0, val.length - 2);
    return parseInt(copiedPx, 10);
  }
  const copiedRem = val.slice(0, val.length - 3);
  const cleaned = parseFloat(copiedRem, 10);
  const viewportW = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (viewportW / 1440) * (cleaned * 10);
};

const Capitalise = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export {
  FormatTitleCase,
  ClosestNumber,
  ClosestLowerNumber,
  ConvertToRange,
  Debounce,
  IsNumeric,
  GetRandomInt,
  Between,
  HasClass,
  RemoveSpaces,
  Shuffle,
  ArrayRotate,
  ParsePixels,
  Capitalise
};
