import React, { useRef, useEffect, useState, Fragment } from "react";
import ExhibitionsStyles from "./Exhibitions.styled";
import useFetch from "../../lib/useFetch";
import ExhibitionListContainer from "./ExhibitionListContainer";
import { useAppStore } from "../../stores/AppStore";
import Preview from "../Preview";

function Exhibitions({ history }) {
  const [
    { isFrench, isFontLoaded, colour2, previewedExhibition },
    { setPreviewedExhibition }
  ] = useAppStore();
  const resetTimer = useRef();
  const moreItemsLoading = useRef(false);
  const previousData = useRef([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState();
  const [itemActive, toggleItemActive] = useState();
  const [exhibitions, setExhibitions] = useState([]);

  const page = `&page=${curPage}`;
  const href = `/api/exhibitions.json?lang=${
    isFrench ? "smallvilleFr" : "smallvilleEn"
  }${page}`;

  const method = "GET";
  const cache = "reload";
  const body = null;
  const response = useFetch({ href, method, body, cache });

  useEffect(() => {
    if (response.meta) {
      const tempTotal = parseInt(response.meta.pagination.total_pages, 10);
      const tempCurrent = parseInt(response.meta.pagination.current_page, 10);
      if (!total) {
        setTotal(tempTotal);
      }
      setCurPage(tempCurrent);
      setHasNextPage(tempTotal !== tempCurrent);
    }
  }, [response]);

  useEffect(() => {
    if (response.data && previousData.current !== response.data) {
      setExhibitions(prev => [...prev, ...response.data]);
      previousData.current = response.data;
      moreItemsLoading.current = false;
    }
  }, [curPage, response, exhibitions]);

  useEffect(() => {
    clearTimeout(resetTimer.current);
    const item = exhibitions[itemActive];
    if (item) {
      setPreviewedExhibition(item);
    }
    return () => {
      clearTimeout(resetTimer.current);
    };
  }, [itemActive]);

  const loadMore = () => {
    if (hasNextPage) {
      setCurPage(state => state + 1);
      moreItemsLoading.current = true;
    }
  };

  const onMouseMove = e => {
    if (!e || !e.target || !response) {
      return;
    }
    if (e.target.classList.contains("exhibition-container")) {
      clearTimeout(resetTimer.current);
      resetTimer.current = setTimeout(() => {
        setPreviewedExhibition(undefined);
      }, 100);
      return;
    }
  };

  if (!response || !response.data) {
    return null;
  }

  return (
    <ExhibitionsStyles
      style={{ background: colour2 || "initial" }}
      className="exhibition-container"
      onMouseMove={onMouseMove}
    >
      {isFontLoaded ? (
        <>
          <ExhibitionListContainer
            items={exhibitions}
            moreItemsLoading={moreItemsLoading}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
            colour2={colour2}
            isFrench={isFrench}
            toggleItemActive={toggleItemActive}
          />
          <Preview isVisible={true} isHardCut={true} />
        </>
      ) : null}
    </ExhibitionsStyles>
  );
}

export default Exhibitions;
