import React, { useEffect, Suspense, useRef, useState } from "react";
import fetch from "../../lib/fetch";
import { useAppStore } from "../../stores/AppStore";
import AboutStyles from "./About.styled";
import Layout from "../Layout";
import ImageBlock from "../ImageBlock";
const Shop = React.lazy(() => import("../Shop"));
import useIntersectionObserver from "../../lib/useIntersectionObserver";
import rawMarkup from "../../lib/rawMarkup";

import "smoothscroll-polyfill";

function AboutQuery({ location }) {
  const [{ isFrench, colour1 }] = useAppStore();
  const elem = useRef();
  const response = fetch({
    href: `/api/about.json?lang=${isFrench ? "smallvilleFr" : "smallvilleEn"}`,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const socialResponse = fetch({
    href: "/api/socialMedia.json",
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const config = {
    rootMargin: "100px 100px 0px 100px"
  };

  const isShopVisible = useIntersectionObserver({
    elem,
    config,
    isOnce: false
  });

  const [isShopActive, setIsShopActive] = useState(false);

  useEffect(() => {
    setIsShopActive(isShopVisible);
  }, [isShopVisible]);

  const renderSVG = (image, link, i) => {
    if (link) {
      return (
        <a href={link} key={i}>
          <img className="svg" src={image.svg} alt={image.title} />
        </a>
      );
    }
    return <img className="svg" src={image.svg} alt={image.title} key={i} />;
  };

  const renderImage = (image, link, i) => {
    if (link) {
      return (
        <a href={link} key={i}>
          <ImageBlock
            noLazyLoad={true}
            noAnimated={true}
            imageImager={image.imageImager}
            title={image.title}
            name="image-block"
          />
        </a>
      );
    }
    return (
      <ImageBlock
        noLazyLoad={true}
        noAnimated={true}
        imageImager={image.imageImager}
        title={image.title}
        name="image-block"
        key={i}
      />
    );
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToShop = () => {
    const { top } = elem.current.getBoundingClientRect();
    window.scrollTo({ top: top, behavior: "smooth" });
  };

  if (!response || !response.data) {
    return null;
  }

  const { layout, associations } = response.data[0];

  return (
    <AboutStyles className="about" style={{ background: colour1 || "initial" }}>
      <nav className="about-nav">
        <section className="wrapper">
          <button
            onClick={() => {
              scrollToTop();
              setIsShopActive(false);
            }}
          >
            <span
              className={`indicator ${
                isShopActive ? "is-inactive" : "is-active"
              }`}
            >
              &rarr;&nbsp;
            </span>
            <span>{isFrench ? "À Propos" : "About"}</span>
          </button>
          <button
            onClick={() => {
              scrollToShop();
              setIsShopActive(true);
            }}
          >
            <span
              className={`indicator ${
                !isShopActive ? "is-inactive" : "is-active"
              }`}
            >
              &rarr;&nbsp;
            </span>
            <span>Shop</span>
          </button>
        </section>
      </nav>
      <Layout layout={layout} />
      {socialResponse.data ? (
        <section className="social">
          <span>{isFrench ? "Suivez-nous" : "Follow us"}</span>
          {socialResponse.data[0].map((social, i) => (
            <span
              key={i}
              className="network"
              dangerouslySetInnerHTML={social && rawMarkup(social.content)}
            />
          ))}
        </section>
      ) : null}
      <section className="associations">
        {associations.map((item, i) => {
          if (item.image[0].svg) {
            return renderSVG(item.image[0], item.link, i);
          }
          if (item.image[0].imageImager) {
            return renderImage(item.image[0], item.link, i);
          }
          return null;
        })}
      </section>
      <section ref={elem} className="shop-wrapper">
        <Suspense fallback={null}>
          <Shop location={location} />
        </Suspense>
      </section>
    </AboutStyles>
  );
}

function About({ location }) {
  return (
    <Suspense fallback={null}>
      <AboutQuery location={location} />
    </Suspense>
  );
}

export default About;
