// ****************************************/
// Loader
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const LoaderStyles = styled.canvas`
  position: fixed;
  bottom: 1.4rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 5;
`;

export default LoaderStyles;
