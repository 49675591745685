import BezierEasing from "bezier-easing";
import Store from ".";

const [html, body] = [document.documentElement, document.body];
const ticking = false;
let lastScrollTop;

const initialState = {
  easing: BezierEasing(0.645, 0.045, 0.355, 1),
  duration: 700,
  isBodyLocked: false,
  colour1: "",
  colour2: "",
  isFrench:
    !window.localStorage ||
    !localStorage.SmallvilleIsFrench ||
    JSON.parse(localStorage.SmallvilleIsFrench).isFrench,
  isNewsletterActive: false,
  isFooterActive: false,
  isFontLoaded: false,
  exhibitions: [],
  previewedExhibition: undefined,
  isInverted: false,
  scrollTop: 0,
  activeExhibitionId: undefined
};

function useAppStore() {
  const [state, setState] = Store.useStore(initialState);

  const setIsBodyLocked = val => {
    setState(draft => {
      draft.isBodyLocked = val;
    });
  };

  const setColour1 = val => {
    setState(draft => {
      draft.colour1 = val;
    });
  };

  const setColour2 = val => {
    setState(draft => {
      draft.colour2 = val;
    });
  };

  const setIsFrench = val => {
    setState(draft => {
      draft.isFrench = val;
    });
  };

  const setIsNewsletterActive = val => {
    setState(draft => {
      draft.isNewsletterActive = val;
    });
  };

  const setIsFooterActive = val => {
    setState(draft => {
      draft.isFooterActive = val;
    });
  };

  const setIsFontLoaded = val => {
    setState(draft => {
      draft.isFontLoaded = val;
    });
  };

  const setExhibitions = val => {
    const newExhibitions = [...state.exhibitions, ...val];
    setState(draft => {
      draft.exhibitions = newExhibitions;
    });
  };

  const clearExhibitions = val => {
    setState(draft => {
      draft.exhibitions = [];
    });
  };

  const setPreviewedExhibition = val => {
    setState(draft => {
      draft.previewedExhibition = val;
    });
  };

  const setIsInverted = val => {
    setState(draft => {
      draft.isInverted = val;
    });
  };

  const setScrollTop = val => {
    setState(draft => {
      draft.scrollTop = val;
    });
  };

  const setActiveExhibitionId = val => {
    setState(draft => {
      draft.activeExhibitionId = val;
    });
  };

  return [
    state,
    {
      setIsBodyLocked,
      setColour1,
      setColour2,
      setIsFrench,
      setIsNewsletterActive,
      setIsFooterActive,
      setIsFontLoaded,
      setExhibitions,
      setPreviewedExhibition,
      setIsInverted,
      setScrollTop,
      clearExhibitions,
      setActiveExhibitionId
    }
  ];
}

export { initialState, useAppStore };
