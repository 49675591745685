// ****************************************/
// Button
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const ButtonStyles = styled.button`
  text-align: center;
  padding: ${props => props.theme.buttonPadding};
  pointer-events: auto;
  background: transparent;
  font-size: ${font.p};
  outline: 0;
  border: 1px solid ${props => props.theme.grey300};
  margin-top: 1px;
  ${media.smallDesktopAndBelow`
    font-size: ${font.pTablet};
    padding: ${props => props.theme.buttonPaddingMobile};
  `}
  ${media.mobileOnly`
    font-size: ${font.pMobile};
  `}

  &.inverse {
    color: white;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  /* &:focus:after,
  &:hover:after {
    position: absolute;
    content: "";
    height: 0;
    bottom: 1.2rem;
    width: calc(100% - 2rem);
    border-bottom: 1px solid black;
    ${media.smallDesktopAndBelow`
      width: calc(100% - 20px);
      left: 12px;
      bottom: 12px;
    `}
  } */

  &.secondary span {
    border-bottom: 1px solid transparent;
  }

  &.secondary:focus span,
  &.secondary:hover span {
    border-bottom: 1px solid black;
  }

  &.secondary.inverse:focus span,
  &.secondary.inverse:hover span {
    border-bottom: 1px solid white;
  }

  &.tertiary {
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
    border: 1px solid black;
  }

  &.tertiary span{
    transition: color 0.25s ease-in-out;
  }

  &.tertiary:hover {
    background: black;
  }

  &.tertiary:hover span {
    color: white;
  }
`;

export default ButtonStyles;
