import React, { useRef, useState, useEffect, Fragment } from "react";
import { animated, useSpring } from "react-spring";
import PropTypes from "prop-types";
import ImageBlock from "../ImageBlock";
import Video from "../Video";

function CarouselSlide({ title, video, imageImager }) {
  return (
    <Fragment>
      {imageImager && imageImager.length ? (
        <ImageBlock
          noLazyLoad={true}
          noAnimated={true}
          imageImager={imageImager}
          title={title}
          name="image-block"
        />
      ) : null}
      {video ? <Video url={video} /> : null}
    </Fragment>
  );
}

CarouselSlide.propTypes = {
  caption: PropTypes.object,
  imageImager: PropTypes.array,
  video: PropTypes.string,
  title: PropTypes.string
};

export default CarouselSlide;
