import React, { useState } from "react";
import LayoutStyles from "./Layout.styled";
import PropTypes from "prop-types";
import rawMarkup from "../../lib/rawMarkup";
import { useAppStore } from "../../stores/AppStore";
import ImageBlock from "../ImageBlock";
import Carousel from "../Carousel";
import Video from "../Video";

function Layout({ layout }) {
  return (
    <LayoutStyles className="layout">
      {layout.map((item, i) => {
        if (item.text && item.text.size === "heading") {
          return (
            <h2
              dangerouslySetInnerHTML={
                item.text && rawMarkup(item.text.text.content)
              }
              key={i}
            />
          );
        }
        if (item.text && item.text.size === "paragraph") {
          return (
            <p
              dangerouslySetInnerHTML={
                item.text && rawMarkup(item.text.text.content)
              }
              key={i}
            />
          );
        }
        if (item.image && item.image.images.length === 1) {
          return (
            <ImageBlock
              key={i}
              noLazyLoad={true}
              noAnimated={true}
              imageImager={item.image.images[0][0].imageImager}
              title={item.image.images[0][0].title}
              name="image-block"
              imageCaption={item.image.caption}
            />
          );
        }
        if (item.image && item.image.images.length > 1) {
          return (
            <Carousel
              key={i}
              slides={item.image.images}
              caption={item.image.caption}
            />
          );
        }
        if (item.video) {
          return (
            <Video
              key={i}
              url={item.video.video}
              caption={item.video.caption}
            />
          );
        }
        return null;
      })}
    </LayoutStyles>
  );
}

PropTypes.Layout = {
  layout: PropTypes.array
};

export default Layout;
