// ****************************************/
// My Module
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring";
import { font, media, underline } from "../../Styles";

const CursorStyles = styled(animated.section)`
  position: fixed;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 3;
  fill: white;
  opacity: 1;

  @supports (mix-blend-mode: difference) {
    color: black;
    fill: white;
    mix-blend-mode: difference;
  }

  @media (pointer: none), (pointer: coarse) {
    opacity: 0;
    visibility: hidden;
    svg {
      visibility: hidden;
    }
  }

  svg {
    position: relative;
    width: 15px;
    height: 15px;
  }

  &.left,
  &.right {
    opacity: 1;
  }

  &.left .right-arrow-svg {
    display: none;
  }

  &.right .left-arrow-svg {
    display: none;
  }
`;

export default CursorStyles;
