import React, { useEffect, useState } from "react";

function useFetch({ href, method, body, cache }) {
  const getData = async () => {
    const headers = new Headers({
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest"
    });

    const request = new Request(href, {
      method,
      mode: "same-origin",
      credentials: "include",
      headers,
      body,
      cache
    });

    const data = await fetch(request) // await waits for promise to resolve inside async function
      .then(async response => {
        if (!response.ok) {
          console.log("Response: " + response.status);
        }
        const result = await response.json();
        return result;
      })
      .catch(err => {
        console.log("Error: ", err);
      });
    return data;
  };
  const [response, setResponse] = useState({});

  useEffect(() => {
    (async () => {
      const data = await getData();
      setResponse(data);
    })();
  }, [href]);

  return response;
}

// const useFetch = (href, method, body, cache) => {
//   const [response, setResponse] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const FetchData = async () => {
//       const headers = new Headers({
//         Accept: "application/json",
//         "X-Requested-With": "XMLHttpRequest"
//       });

//       const request = new Request(href, {
//         method,
//         mode: "same-origin",
//         credentials: "include",
//         headers,
//         body,
//         cache
//       });

//       try {
//         const res = await fetch(request);
//         const json = await res.json();
//         setResponse(json);
//       } catch (error) {
//         setError(error);
//       }
//     };
//     FetchData();
//   }, []);

//   return { response, error };
// };

export default useFetch;
