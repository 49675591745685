// ****************************************/
// Global Styles & Resets
// ****************************************/

import { createGlobalStyle } from "styled-components";
import media from "./Media";
import animation from "./Animation";
import font from "./Font";
import ArsenicTTF from "../../../fonts/arsenic.ttf";
import ArsenicWoff2 from "../../../fonts/arsenic.woff2";
import ArsenicWoff from "../../../fonts/arsenic.woff";

const GlobalStyles = createGlobalStyle`
	*{
		margin: 0;
		padding: 0;
	}

	html{
		box-sizing: border-box;
		-webkit-print-color-adjust: exact;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	*, *:before, *:after{
		box-sizing: inherit;
	}

	body,h1,h2,h3,h4,p{
		border: 0;
	}

	a {
		color: black;
	}

  .isTouch .cursor-nav,
  .isTouch .cursor-nav svg{
    visibility: hidden;
  }


	h1, h2, h3, h4, h5, h6, p, a, li, span, input, label, button, em, figcaption {
		font-family: ${font.family};
		overflow-wrap: break-word;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-weight: normal;
    font-variant-numeric: oldstyle-nums;
    font-feature-settings: "kern" 1;
		letter-spacing: ${font.letterSpacing};
		${media.smallDesktopAndBelow`
			letter-spacing: ${font.letterSpacingMobile};
		`}
	}
	
	body {
		line-height: 1;
    overscroll-behavior: none;
  }

	::selection{
		background: ${props => props.theme.selectionHighlightColor};
		color: ${props => props.theme.selectionColor};
	}

	#site{
		margin-left: auto;
		margin-right: auto;
	}

	.main{
		background: white;
	}

	.flexwrap{
		display: flex;
		flex-direction: column;
	}

	.flexwrap main,
	.flexwrap-item{
		flex: 1;
	}

	.flexwrap main > .page-wrap {
		position: relative;
		width: 100%;
    min-height: 100vh;
	}


	a{
		outline: 0;
		text-decoration: none;
	}

	ul{
		text-decoration: none;
		list-style-type: none;
		padding: 0;
	}

	.page-context{
		position: relative;
		font-size: 0;
	}

	h1, h2, h3, h4, h5, h6, p, a, li, span, input, label, small, button, em, figcaption{
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		font-weight: normal;
		font-feature-settings: "kern" 1;
		font-variant-numeric: tabular-nums;
		color: ${props => props.theme.black};
    font-variation-settings: "wght" 80;
	}

	html, body{
		font-size:  ${props => (props.theme.baseline * 100) / props.theme.viewport}vmax
		// font-size: 0.694vmax
		// 1440px times 0.694 = 10px base
	}

	button:hover {
		cursor: pointer;
	}

	picture{
		position: relative;
  	display: block;
	}

	img{
		width: 100%;
	}

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus{
	  -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.grey100} inset;
  }

  @font-face {
    font-family: 'Arsenic';
    src: url('${ArsenicWoff2}') format('woff2');
    src: url('${ArsenicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Arsenic';
    src: url('${ArsenicTTF}') format('truetype');
  }
}
`;

export default GlobalStyles;
