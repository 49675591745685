import React, { Fragment } from "react";
import WithLightbox from "../components/WithLightbox";
import AboutContainer from "../components/About";

function About({ history, location }) {
  return (
    <WithLightbox
      history={history}
      location={location}
      Component={AboutContainer}
    />
  );
}

export default About;
