// ****************************************/
// Preview
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";
import { animated } from "react-spring";

const PreviewStyles = styled(animated.section)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  pointer-events: none;
  z-index: 2;
  padding: 2rem 0 6rem 0;
  width: 71rem;
  margin: auto;
  ${media.tabletLandscapeAndBelow`
    width: calc(100% - 250px);
  `}
  ${media.tabletPortraitAndBelow`
    width: calc(100% - 70px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px 20px 60px 20px;
  `}

  .image-block {
    position: relative;
    margin-top: 5rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    ${media.smallDesktopAndBelow`
      margin-top: 50px;
    `}
  }
`;

export default PreviewStyles;
