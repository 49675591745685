// ****************************************/
// With Lightbox
// ****************************************/

import styled from "styled-components";
import { font, media } from "../Styles";
import { animated } from "react-spring";

const WithLightboxStyles = styled(animated.section)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;

  .close {
    position: fixed;
    top: 0;
    right: 0;
  }
`;

export default WithLightboxStyles;
