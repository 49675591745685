import React, { Suspense } from 'react';
import MetaTags from 'react-meta-tags';
import fetch from '../../lib/fetch';
import { Shuffle } from '../../lib/Utils';

function MetaContainer() {
  const response = fetch({
    href: '/api/meta.json',
    method: 'GET',
    body: null,
    cache: 'force-cache'
  });

  // const favicons = fetch({
  //   href: '/api/favicon.json',
  //   method: 'GET',
  //   body: null,
  //   cache: 'force-cache'
  // });

  if (!response) {
    return null;
  }

  const { googleSeoDescription, googleSeoKeywords, socialCard } = response;
  // const { gallery } = favicons;

  // const slides = gallery.reduce((a, b) => {
  //   return a.concat(b[0].concat(b[1]));
  // }, []);

  // const filtered = Shuffle(slides)[0];
  // const favicon180 = filtered.image.imageImager[0].url;
  // const favicon32 = filtered.image.imageImager[1].url;
  // const favicon16 = filtered.image.imageImager[2].url;

  return (
    <MetaTags>
      <meta charSet="UTF-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="ie=edge" httpEquiv="X-UA-Compatible" />
      {/* <link rel="apple-touch-icon" sizes="180x180" href={favicon180} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} /> */}
      <meta name="twitter:card" content="summary_large_image" />
      {googleSeoDescription ? (
        <meta name="description" content={`${googleSeoDescription}`} />
      ) : null}
      {googleSeoKeywords ? (
        <meta name="keywords" content={`${googleSeoKeywords}`} />
      ) : null}
      {socialCard ? (
        <meta name="twitter:image" content={socialCard[0].url} />
      ) : null}
      {socialCard ? <meta name="og:image" content={socialCard[0].url} /> : null}
      <title>Smallville</title>
    </MetaTags>
  );
}

function Meta() {
  return (
    <Suspense fallback={null}>
      <MetaContainer />
    </Suspense>
  );
}

export default Meta;
