// ****************************************/
// LogoAnimator
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const LogoAnimatorStyles = styled.canvas`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
  background: black;
`;

export default LogoAnimatorStyles;
