// ****************************************/
// Grid
// ****************************************/

// -----------------------------------------

// Usage example

//
// 	${grid}
//

import { css } from 'styled-components';

const columns = 24;

function range(x0, x1, step = 1) {
  function recurse(currVal, results) {
    if (currVal >= x1) {
      return results;
    }
    return recurse(currVal + step, [...results, currVal]);
  }
  return recurse(x0, []);
}

// Iterate through the columns
function grid() {
  let styles = '';

  range(1, columns + 2).forEach(key => {
    styles += `
			.col-start-${key} {
				grid-column-start: ${key};
			}
			.col-end-${key} {
				grid-column-end: ${key};
			}
     `;
  });

  return css`
    ${styles}
  `;
}

export default grid;
