// ****************************************/
// Carousel
// ****************************************/

import styled from "styled-components";
import { animated } from "react-spring";
import { font, media, underline, animation } from "../Styles";

const CarouselStyles = styled(animated.section)`
  position: relative;
  width: 100%;
  font-size: 0;
  height: 0;

  & {
    transition: ${props => props.theme.transitionOpacity};
    margin-bottom: 7.2rem;
  }

  & {
    ${media.smallDesktopAndBelow`
      margin-bottom: 72px;
    `}
  }

  &.is-hidden {
    opacity: 0;
  }

  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slide .image-block {
    position: relative;
  }

  .slide .image-block img {
    object-fit: contain;
  }

  .slide .image-block > section {
    height: 100%;
  }

  .slide img {
    height: 100%;
  }

  .is-filled img {
    object-fit: cover;
    object-position: top center;
  }

  .slide figure {
    padding-bottom: initial !important;
  }

  &:hover {
    cursor: none;
  }

  .cursor-nav {
    display: none;
  }

  &:hover .cursor-nav {
    display: block;
  }

  ${animation.nudgeLeft};
  ${animation.nudgeRight};

  .slide.nudge-left {
    animation: nudgeLeft 0.7s ${props => props.theme.cubicBezier} forwards;
  }

  .slide.nudge-right {
    animation: nudgeRight 0.7s ${props => props.theme.cubicBezier} forwards;
  }

  .slide {
    user-select: none;
  }

  .slide > section {
    pointer-events: none;
  }

  .slide .image-block {
    height: 100%;
  }

  .caption {
    position: absolute;
    z-index: 4;
    display: block;
    font-size: ${font.p};
    line-height: 1.33;
    bottom: -3rem;
    ${media.smallDesktopAndBelow`
      font-size: ${font.pTablet};
      bottom: -30px;
    `}
    ${media.mobileOnly`
      font-size: ${font.pMobile};
    `}
  }

  .caption {
    left: 0;
    text-align: left;
  }

  .ui {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    padding: 0 1rem;
    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
    @media (pointer: none), (pointer: coarse) {
      opacity: 1;
    }
    ${media.smallDesktopAndBelow`
      padding: 0 10px;
    `}
  }

  .ui span {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    @supports (mix-blend-mode: difference) {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .ui svg {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
  }

  .ui > span:nth-child(1) svg {
    left: calc(50% - 2px);
  }

  .ui > span:nth-child(2) svg {
    left: calc(50% + 1px);
  }
`;

export default CarouselStyles;
