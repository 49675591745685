const theme = {
  black: "#000000",
  grey: "#ADADAD",
  selectionHighlightColor: "black",
  selectionColor: "white",
  cubicBezier: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  viewport: 1440, // define viewport of development macnine for accurate rem sizes
  baseline: 10,
  buttonPadding: "1.2rem 1.2rem 0.8rem 1.2rem",
  buttonPaddingMobile: "10px 12px 8px 12px",
  greyOpacity100: "rgba(0, 0, 0, 0.15)",
  grey100: "#FAFAFA",
  grey300: "#ADADAD"
};

export default theme;
