import React, { Suspense, useState, useEffect, useRef } from "react";
import LogoAnimatorStyles from "./LogoAnimator.styled";
import useWindowSize from "../../lib/useWindowSize";
import { useAppStore } from "../../stores/AppStore";
import fetch from "../../lib/fetch";
import { GetRandomInt } from "../../lib/Utils";
import createActivityDetector from "activity-detector";
import useKeyPress from "../../lib/useKeyPress";

function LogoAnimatorQuery({ isAlwaysActive }) {
  const sPress = useKeyPress("s");
  const mPress = useKeyPress("m");
  const [{ isInverted }, { setIsInverted }] = useAppStore();
  const canvas = useRef();
  const ctx = useRef();
  const x = useRef();
  const y = useRef();
  const dx = useRef(1.5);
  const dy = useRef(-1.5);
  const [image, setImage] = useState();
  const [imageData, setImageData] = useState();
  const ratio = 1;
  const { viewportW, viewportH } = useWindowSize();
  const animFrame = useRef();
  const ballRadius =
    !viewportW || viewportW < 600 ? (viewportW / 3) * 2 : viewportW / 3;
  const [isActive, setIsActive] = useState(isAlwaysActive === true);
  const pixelRatio = window.devicePixelRatio || 1;
  const detector = createActivityDetector({
    timeToIdle: !viewportW || viewportW < 600 ? 8000 : 15000,
    inactivityEvents: [],
    activityEvents: [
      "click",
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "scroll"
    ],
    ignoredEventsWhenIdle: []
  });

  const response = fetch({
    href: `/api/logos.json`,
    method: "GET",
    body: null,
    cache: "no-cache"
  });

  const clear = () => {
    ctx.current.save();
    ctx.current.setTransform(1, 0, 0, 1, 0, 0); // set transform to non-retina before clearing
    ctx.current.clearRect(0, 0, viewportW * pixelRatio, viewportH * pixelRatio);
    ctx.current.restore();
  };

  const getImageDimensions = () => {
    const naturalWidth = parseInt(imageData.width, 10);
    const naturalHeight = parseInt(imageData.height, 10);
    const maxWidth = ballRadius;
    const maxHeight = ballRadius;

    const imgRatio = Math.min(
      maxWidth / naturalWidth,
      maxHeight / naturalHeight
    );

    const tempImageWidth = naturalWidth * imgRatio;
    const tempImageHeight = naturalHeight * imgRatio;

    const imageWidth = Math.abs(tempImageWidth);
    const imageHeight = Math.abs(tempImageHeight);

    return {
      w: imageWidth,
      h: imageHeight,
      x: x.current,
      y: y.current
    };
  };

  const drawImage = dimensions => {
    ctx.current.drawImage(
      image,
      dimensions.x,
      dimensions.y,
      dimensions.w,
      dimensions.h
    );
  };

  const draw = () => {
    if (!canvas.current) {
      window.cancelAnimationFrame(animFrame.current);
      return;
    }
    clear();
    const dimensions = getImageDimensions();
    drawImage(dimensions);

    if (
      x.current + dx.current >=
        (canvas.current.width - dimensions.w * pixelRatio) / pixelRatio ||
      x.current + dx.current <= 0
    ) {
      dx.current = -dx.current;
    }
    if (
      y.current + dy.current >=
        (canvas.current.height - dimensions.h * pixelRatio) / pixelRatio ||
      y.current + dy.current <= 0
    ) {
      dy.current = -dy.current;
    }

    x.current += dx.current;
    y.current += dy.current;

    animFrame.current = window.requestAnimationFrame(draw);
  };

  const layout = () => {
    window.cancelAnimationFrame(animFrame.current);
    animFrame.current = window.requestAnimationFrame(draw);
  };

  const setup = () => {
    ctx.current = canvas.current.getContext("2d");
    x.current = 0;
    y.current = 0;
  };

  const enableHighDPICanvas = () => {
    // if (pixelRatio === 1) return;
    canvas.current.width = viewportW * pixelRatio;
    canvas.current.height = viewportH * pixelRatio;
    ctx.current.scale(pixelRatio, pixelRatio);
  };

  useEffect(() => {
    if (canvas.current && image && isActive) {
      setup();
      enableHighDPICanvas();
      layout();
    }
  }, [canvas, image, isActive]);

  const supportsWebp = async () => {
    if (!self.createImageBitmap) return false;

    const webpData =
      "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
    const blob = await window.fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(
      () => true,
      () => false
    );
  };

  const imageLoad = async url => {
    const tempImage = new Image();
    tempImage.onload = () => {
      return 1;
    };
    tempImage.src = url;
    if (tempImage.complete) {
      return 1;
    }
  };

  const getImage = async () => {
    const random = GetRandomInt(0, response.data.length - 1);
    const newImage = (await supportsWebp())
      ? response.data[random].logo[0].imageImager[2]
      : response.data[random].logo[0].imageImager[0];
    await imageLoad(newImage.url);
    const tempImage = new Image();
    tempImage.src = newImage.url;
    setImageData(newImage);
    setImage(tempImage);
  };

  useEffect(() => {
    if (response.data && isActive) {
      getImage();
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      setIsInverted(true);
    } else {
      setIsInverted(false);
    }
    return () => {
      setIsInverted(false);
    };
  }, [isActive]);

  detector.on("active", () => {
    if (!isAlwaysActive) {
      setIsActive(false);
    }
  });
  detector.on("idle", () => {
    if (!isAlwaysActive) {
      setIsActive(true);
    }
  });

  useEffect(() => {
    if (sPress && mPress && !isAlwaysActive) {
      setIsActive(true);
    }
  }, [sPress, mPress]);

  useEffect(() => {
    return () => {
      detector.stop();
    };
  }, []);

  const onScroll = () => {
    const scrollTop =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollTop < viewportH && !isInverted) {
      setIsInverted(true);
      return;
    }
    if (scrollTop >= viewportH && isInverted) {
      setIsInverted(false);
    }
  };

  useEffect(() => {
    if (isAlwaysActive) {
      document.addEventListener("scroll", onScroll, {
        capture: true,
        passive: true
      });
    }
    return () => {
      if (isAlwaysActive) {
        document.removeEventListener("scroll", onScroll, {
          capture: true,
          passive: true
        });
      }
    };
  }, [isInverted]);

  if (!response || !response.data || !isActive) {
    return null;
  }

  return (
    <LogoAnimatorStyles
      ref={canvas}
      style={{
        position: !isAlwaysActive ? "fixed" : "relative",
        width: `${viewportW}px`,
        height: `${viewportH}px`
      }}
    />
  );
}

function LogoAnimator({ isAlwaysActive }) {
  return (
    <Suspense fallback={null}>
      <LogoAnimatorQuery isAlwaysActive={isAlwaysActive} />
    </Suspense>
  );
}

export default LogoAnimator;
