import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, interpolate } from 'react-spring';
import CursorStyles from './Cursor.styled';
import useWindowSize from '../../../lib/useWindowSize';

function Cursor({ xy }) {
  const { viewportW } = useWindowSize();
  const [direction, setDirection] = useState('');

  const onMouseMove = e => {
    const { clientX } = e;
    if (clientX <= viewportW / 2) {
      setDirection('left');
      return;
    }
    setDirection('right');
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const transform = xy.interpolate((x, y) => `translate(${x}px, ${y}px)`);

  return (
    <CursorStyles
      style={{
        transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`)
      }}
      className={`cursor-nav ${direction}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        className="left-arrow-svg"
      >
        <path fill="none" d="M0 0H15V15H0z" />
        <path
          fill="#fff"
          d="M10.92 0.04L11.84 0.96 5.3 7.5 11.84 14.04 10.92 14.96 3.46 7.5 10.92 0.04z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        className="right-arrow-svg"
      >
        <path fill="none" d="M0 0H15V15H0z" />
        <path
          fill="#fff"
          d="M4.08 14.96L3.16 14.04 9.7 7.5 3.16 0.96 4.08 0.04 11.54 7.5 4.08 14.96z"
        />
      </svg>
    </CursorStyles>
  );
}

Cursor.propTypes = {
  xy: PropTypes.object
};

export default Cursor;
