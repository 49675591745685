import React, { useEffect, memo, Fragment, Suspense } from "react";
import fetch from "../../lib/fetch";
import { useAppStore } from "../../stores/AppStore";
import Exhibition from "../Exhibition";
import LogoAnimator from "../LogoAnimator";
import HomeStyles from "./Home.styled";
import { useSpring } from "react-spring";
import InfiniteScroll from "../InfiniteScroll";
import Loader from "../Loader";

function HomeQuery({ isHome }) {
  const [
    { isFrench, colour1, colour2 },
    { setColour1, setColour2 }
  ] = useAppStore();
  const paths = window.location.pathname.split("/");

  const hasUrlParam = paths.length > 2 ? true : false;
  const slug = hasUrlParam ? paths[2] : null;

  const exhibitionFromSlug = slug
    ? fetch({
        href: `/api/exhibition/${slug}.json?lang=${
          isFrench ? "smallvilleFr" : "smallvilleEn"
        }`,
        method: "GET",
        body: null,
        cache: "reload"
      })
    : { data: [] };

  const currentId = exhibitionFromSlug.data.length
    ? parseInt(exhibitionFromSlug.data[0].id, 10)
    : null;

  const exhibitions = fetch({
    href: `/api/exhibitions.json?lang=${
      isFrench ? "smallvilleFr" : "smallvilleEn"
    }`,
    method: "GET",
    body: null,
    cache: "reload"
  });

  const colour = fetch({
    href: "/api/colour.json",
    method: "GET",
    body: null,
    cache: "reload"
  });

  const colourExhibition = fetch({
    href: "/api/colourExhibition.json",
    method: "GET",
    body: null,
    cache: "reload"
  });

  if (!exhibitions.data || !colour.data) {
    return null;
  }

  // const palettes = colourExhibition.data[0].colourPalette;
  // const tempColour1 = colourExhibition.data.length
  //   ? colourExhibition.data[0].dominantColour
  //   : colour.data[0].featuredColour1;
  // const tempColour2 = colourExhibition.data.length
  //   ? Shuffle(palettes)[0]
  //   : colour.data[0].featuredColour2;

  const tempColour1 = colour.data[0].featuredColour1;
  const tempColour2 = colour.data[0].featuredColour2;

  useEffect(() => {
    if (!colour1 || !colour2) {
      setColour1(tempColour1);
      setColour2(tempColour2);
    }
  }, []);

  const currentExhibitions =
    currentId === null
      ? exhibitions.data.filter(
          item => item.date.isOnGoing || item.date.isFuture
        )
      : exhibitionFromSlug.data;

  const next = currentExhibitions[currentExhibitions.length - 1];
  const nextId = next ? parseInt(next.id, 10) : currentId;

  const [{ scrollTop, easing, duration, isBodyLocked }] = useAppStore();
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration, easing }
  });

  return (
    <HomeStyles
      style={{
        overflow: isBodyLocked ? "hidden" : "visible",
        top: scrollTop !== 0 ? `${-scrollTop}px` : "",
        position: isBodyLocked ? "fixed" : "relative",
        ...props
      }}
    >
      {currentExhibitions.length
        ? currentExhibitions.map((exhibition, i) => (
            <Exhibition
              title={exhibition.title}
              subtitle={exhibition.subtitle}
              id={exhibition.id}
              uri={exhibition.uri.split("/")[1]}
              featuredImage={exhibition.featuredImage}
              date={exhibition.date}
              artists={exhibition.artists}
              layout={exhibition.layout}
              exhibitionDate={exhibition.date}
              hideEndDate={exhibition.hideEndDate}
              key={i}
            />
          ))
        : null}
      {isHome ? (
        <LogoAnimator isAlwaysActive={!currentExhibitions.length} />
      ) : null}
      {isHome && !exhibitionFromSlug.data.length ? (
        <InfiniteScroll
          nextId={nextId}
          currentId={currentId}
          showPrevious={currentId === null}
          isFrench={isFrench}
          currentExhibitions={currentExhibitions}
        />
      ) : null}
    </HomeStyles>
  );
}

function Home({ isHome }) {
  return (
    <Suspense fallback={<Loader />}>
      <HomeQuery isHome={isHome} />
    </Suspense>
  );
}

export default Home;
