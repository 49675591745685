// ****************************************/
// Animations
// ****************************************/

import { css } from "styled-components";
import media from "./Media";

const fadeIn = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const hover = css`
  @keyframes hover {
    0% {
      font-variation-settings: "wght" 80;
    }
    100% {
      font-variation-settings: "wght" 90;
    }
  }
`;

const scaleFadeout = css`
  @keyframes scaleFadeout {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.85);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
`;

const sound = css`
  @keyframes sound {
    0% {
      opacity: 0.35;
      height: 0.3rem;
      ${media.smallDesktopAndBelow`
				height: 3px;
			`}
    }
    100% {
      opacity: 1;
      height: 1.8rem;
      ${media.smallDesktopAndBelow`
				height: 18px;
			`}
    }
  }
`;

const nudgeLeft = css`
  @keyframes nudgeLeft {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(-100px, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const nudgeRight = css`
  @keyframes nudgeRight {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(100px, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const animation = {
  fadeIn,
  hover,
  scaleFadeout,
  sound,
  nudgeLeft,
  nudgeRight
};

export default animation;
