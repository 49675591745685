// ****************************************/
// Exhibitions
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const ExhibitionsStyles = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  padding-top: 1.8rem;
  display: flex;
  flex-direction: column;
  margin-left: 25.5rem;
  ${media.smallDesktopAndBelow`
    padding-top: 18px;
  `}
  ${media.tabletLandscapeAndBelow`
    margin-left: 35px;
  `}
  ${media.tabletPortraitAndBelow`
    width: calc(100% - 35px);
    margin-left: auto;
  `}

  button {
    outline: 0;
    border: 0;
    background: transparent;
  }

  .header {
    position: fixed;
    top: 0;
    border-bottom: 1px solid transparent;
    display: flex;
    transition: border-color 0.25s ease-in-out;
    z-index: 5;
    width: calc(100% - 51rem);
    padding: 2.1rem 0 1rem 0;
    padding-left: 60px;
    padding-right: 60px;
    left: 50%;
    transform: translateX(-50%);
    ${media.tabletLandscapeAndBelow`
      margin-left: auto;
      width: calc(100% - 35px);
      left: 35px;
      transform: initial;
    `}
    ${media.mobileOnly`
      padding: 0;
      left: 50px;
      width: calc(100% - 160px);
    `}
  }

  .ticker-wrapper,
  .ticker {
    width: 100%;
    white-space: nowrap;
  }

  .header.is-scrolled {
    border-bottom-color: black;
  }

  .header button {
    padding-left: 0;
    text-align: left;
    position: relative;
    pointer-events: none;
  }


  .header > button:nth-child(2) {
    ${media.tabletLandscapeAndBelow`
      display: none;
    `}
  }

  .header > button:nth-child(3) {
    text-align: right;
    ${media.tabletLandscapeAndBelow`
      display: none;
    `}
  }

  .list-container {
    z-index: 4;
    margin-top: 6.9rem;
    ${media.smallDesktopAndBelow`
      margin-top: 69px;
    `}
    -ms-overflow-style: none;
    ${media.tabletPortraitAndBelow`
      -ms-overflow-style: block;
    `}
    ${media.mobileOnly`
      margin-top: 36px;
    `}
  }

  .list-container::-webkit-scrollbar {
    display: none;
    ${media.tabletPortraitAndBelow`
      display: block;
    `}
  }

  .list-container a {
    font-size: ${font.p};
    ${media.smallDesktopAndBelow`
      font-size: ${font.pMobile};
    `}
  }

  .exhibition-list-item {
    width: 100%;
    height: 3.5rem;
    ${media.smallDesktopAndBelow`
      height: 35px;
    `}
  }

  .exhibition-list-item > a {
    text-align: left;
    display: flex;
    width: 100%;
    height: 100%;
    background: transparent;
    padding-left: 60px;
    padding-right: 60px;
    background: inherit;
    align-items: center;
    ${media.tabletLandscapeAndBelow`
      display: block;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    `}
    ${media.mobileOnly`
      padding-left: 15px;
      padding-right: 15px;
    `};
  }

  .exhibition-list-item > a:hover a {
    @supports(mix-blend-mode: difference){
      color: white;
    }
  }

  .exhibition-list-item > a:hover {
    background: black;
    @supports(mix-blend-mode: difference){
      mix-blend-mode: difference;
    }
  }

  .exhibition-list-item a > span {
    display: block;
    padding-top: 0.2rem;
    width: calc(100% - 40px);
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    ${media.tabletLandscapeAndBelow`
      width: 100%;
    `}
  }

  .header > button:nth-child(1),
  .exhibition-list-item a > span.title {
    width: 22rem;
    margin-right: 2rem;
    ${media.tabletLandscapeAndBelow`
      width: 100%;
      margin-right: initial;
    `}
  }

  .header > button:nth-child(2),
  .exhibition-list-item a > span.artists {
    flex: 1;
    display: inline-flex;
  }

  .exhibition-list-item a > span.artists {
    ${media.tabletLandscapeAndBelow`
      display: block;
    `}
  }

  .header > button:nth-child(2){
    ${media.tabletLandscapeAndBelow`
      display: none;
    `}
  }

  .header > button:nth-child(3),
  .exhibition-list-item a > span.dates {
    margin-left: 2rem;
    width: 22rem;
    right: 0;
    text-align: right;
    ${media.tabletLandscapeAndBelow`
      width: 100%;
      text-align: left;
      margin-left: initial;
    `}
  }

  .exhibition-list-item a > span {
    ${media.tabletLandscapeAndBelow`
      padding: 2px 0 0 0;
      /* height: 19px; */
    `}
  }

  .exhibition-list-item a:hover span,
  .exhibition-list-item a:hover p{
    color: white;
  }

  .ticker__element + .ticker__element {
    padding-left: 2rem;
  }

  .exhibition-list-item .artists {
    display: flex;
  }

  .exhibition-list-item .artists span {
    display: inline-flex;
    position: relative;
  }

  .exhibition-list-item .artist a {
    pointer-events: none;
    display: inline-block;
    height: 100%;
  }

  .exhibition-list-item .and {
    padding-left: 5px;
    padding-right: 5px;
  }

  .exhibition-list-item .comma {
    padding-right: 5px;
  }

  .exhibition-list-item .emoji {
    padding: 0 1rem;
  }

  &:before {
    content: '';
    position: fixed;
    display: block;
    background: inherit;
    width: 100vw;
    height: 100vh;
    top: -90vh;
    z-index: 2;
    pointer-events: none;
  }
`;

export default ExhibitionsStyles;
