import React, { Suspense, useState, useEffect, useRef, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import TransitionFade from "../TransitionFade";
const Menu = React.lazy(() => import("../Menu"));
const Preview = React.lazy(() => import("../Preview"));
const Newsletter = React.lazy(() => import("../Newsletter"));
import FontFaceObserver from "fontfaceobserver";
import { useAppStore } from "../../stores/AppStore";

function Router() {
  const [{}, { setIsFontLoaded }] = useAppStore();
  useEffect(() => {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    const font = new FontFaceObserver("Arsenic");
    font.load().then(
      () => {
        setIsFontLoaded(true);
      },
      () => {
        setIsFontLoaded(true);
      }
    );
  }, []);

  return (
    <Route
      render={({ location, history }) => (
        <section className="flexwrap">
          <main>
            <TransitionFade location={location} history={history} />
            <Suspense fallback={null}>
              <Menu location={location.pathname || window.location.pathname} />
              <Newsletter />
            </Suspense>
          </main>
        </section>
      )}
    />
  );
}

export default withRouter(Router);
