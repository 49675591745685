import React, { useState } from "react";
import VideoStyles from "./Video.styled";
import ReactPlayer from "react-player";
import { useInView } from "react-intersection-observer";
import rawMarkup from "../../lib/rawMarkup";

function Video({ url, caption }) {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false
  });

  return (
    <VideoStyles ref={ref} className="video">
      <section className="video-wrapper">
        <ReactPlayer
          url={url}
          playing={inView}
          playsinline={true}
          width="100%"
          height="100%"
          muted={true}
          controls={true}
          loop={true}
          config={{
            youtube: {
              playerVars: { showinfo: 0, controls: 1 }
            }
          }}
        />
      </section>
      {caption ? (
        <p
          className="caption"
          dangerouslySetInnerHTML={caption && rawMarkup(caption.content)}
        />
      ) : null}
    </VideoStyles>
  );
}

export default Video;
