// ****************************************/
// Transition
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const TransitionFadeStyles = styled.section`
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 5;
  }

  .container {
    position: relative;
  }

  .page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .page-enter {
    opacity: 0;
  }

  .page-enter-active {
    opacity: 1;
    transition: opacity 0.7s ${props => props.theme.cubicBezier},
      transform 0.7s ${props => props.theme.cubicBezier};
  }

  .page-exit {
    opacity: 1;
    transform: translate3d(0vw, 0, 0);
  }

  .page-exit-active {
    opacity: 0;
    transition: opacity 0.7s ${props => props.theme.cubicBezier},
      transform 0.7s ${props => props.theme.cubicBezier};
  }

  /* left */
  .from-left .page-enter {
    transform: translate3d(-100vw, 0, 0);
  }

  .from-left .page-enter-active {
    transform: translate3d(0vw, 0, 0);
  }

  .from-left .page-exit-active {
    transform: translate3d(-100vw, 0, 0);
  }

  /* right */
  .from-right .page-enter {
    transform: translate3d(100vw, 0, 0);
  }

  .from-right .page-enter-active {
    transform: translate3d(0vw, 0, 0);
  }

  .from-right .page-exit-active {
    transform: translate3d(100vw, 0, 0);
  }

  & > .preview {
    z-index: 10;
  }
`;

export default TransitionFadeStyles;
