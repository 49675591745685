import React, { useState } from 'react';
import ButtonStyles from './Button.styled';

function Button({
  text,
  name,
  type,
  variant,
  isActive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onTouchCancel,
  onTouchEnd,
  onTouchMove,
  onTouchStart
}) {
  return (
    <ButtonStyles
      onClick={onClick}
      type={type || 'button'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchCancel={onTouchCancel}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      className={`btn ${variant || ''} ${name || ''} ${
        isActive ? 'is-active' : ''
      }`}
    >
      <span>{text}</span>
    </ButtonStyles>
  );
}

export default Button;
