import React, { useEffect, Suspense, useState, useRef } from "react";
import InfiniteScrollStyles from "./InfiniteScroll.styled";
import Loader from "../Loader";
import Exhibition from "../Exhibition";
import rawMarkup from "../../lib/rawMarkup";
import { useInView } from "react-intersection-observer";
import usePrevious from "../../lib/usePrevious";
import useWindowSize from "../../lib/useWindowSize";
import useFetch from "../../lib/useFetch";
import InfiniteScrollLoop from "./InfiniteScrollLoop";

function InfiniteScroll({
  nextId,
  currentId,
  showPrevious,
  isFrench,
  currentExhibitions
}) {
  const [curPage, setCurPage] = useState(1);
  const moreItemsLoading = useRef(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [total, setTotal] = useState();
  const [exhibitions, setExhibitions] = useState([]);
  const page = `&page=${curPage}`;
  const next = nextId !== null ? `&nextId=${nextId}` : "";
  const current = currentId !== null ? `&currentId=${currentId}` : "";
  const href = `/api/exhibitions.json?lang=${
    isFrench ? "smallvilleFr" : "smallvilleEn"
  }${page}${next}${current}`;

  const method = "GET";
  const cache = "reload";
  const body = null;
  const response = useFetch({ href, method, body, cache });

  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: `1000px`,
    triggerOnce: false
  });

  const loadMore = async () => {
    if (hasNextPage) {
      moreItemsLoading.current = true;
      setCurPage(state => state + 1);
    }
  };

  useEffect(() => {
    if (inView && !moreItemsLoading.current && hasNextPage) {
      loadMore();
    }
  }, [inView]);

  useEffect(() => {
    if (response.meta) {
      const tempTotal = parseInt(response.meta.pagination.total_pages, 10);
      const tempCurrent = parseInt(response.meta.pagination.current_page, 10);
      setCurPage(tempCurrent);
      setHasNextPage(tempTotal !== tempCurrent);
      if (!total) {
        setTotal(tempTotal);
      }
    }
  }, [exhibitions]);

  useEffect(() => {
    if (response.data) {
      setExhibitions(prev => [...prev, ...response.data]);
      moreItemsLoading.current = false;
    }
  }, [response]);

  if (!response || !response.data) {
    return <Loader />;
  }

  return (
    <InfiniteScrollStyles className="infinite-scroll">
      {showPrevious ? (
        <section className="previous-exhibition-container">
          <span
            className="previous-exhibition-mark"
            dangerouslySetInnerHTML={rawMarkup(
              !isFrench
                ? "Previous Exhibitions"
                : "Expositions pr&eacute;c&eacute;dentes"
            )}
          />
        </section>
      ) : null}
      {exhibitions.map((item, i) => {
        const {
          title,
          id,
          subtitle,
          featuredImage,
          date,
          artists,
          layout,
          hideEndDate
        } = item;
        return (
          <Exhibition
            key={i}
            title={title}
            id={id}
            subtitle={subtitle}
            featuredImage={featuredImage}
            exhibitionDate={date}
            artists={artists}
            layout={layout}
            hideEndDate={hideEndDate}
          />
        );
      })}
      <span ref={ref} className="sentinel" />
      {!hasNextPage ? (
        <InfiniteScrollLoop
          showPrevious={showPrevious}
          currentExhibitions={currentExhibitions}
          exhibitions={exhibitions}
          isFrench={isFrench}
        />
      ) : null}
    </InfiniteScrollStyles>
  );
}

export default InfiniteScroll;
