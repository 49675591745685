// ****************************************/
// Exhibition
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const ExhibitionStyles = styled.section`
  position: relative;
  height: 100%;
  padding: 2rem 2rem 16rem 2rem;
  width: 89rem;
  margin: auto;
  @supports (mix-blend-mode: difference) {
    background: white;
  }
  ${media.smallDesktopAndBelow`
    width: 890px;
    padding: 20px 20px 160px 20px;
  `}
  ${media.tabletLandscapeAndBelow`
    width: calc(100% - 250px);
  `}
  ${media.tabletPortraitAndBelow`
    width: calc(100% - 70px);
    margin-left: auto;
    margin-right: auto;
    padding-left: initial;
    padding-right: initial;
    padding-bottom: 120px;
    /* padding: 20px 20px 60px 20px; */
  `}

  .exhibition {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .intro {
    left: 50%;
    transform: translateX(-50%);
    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
  }

  .intro section {
    @supports (mix-blend-mode: difference) {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-size: ${font.h1};
    line-height: 1.25;
    ${media.smallDesktopAndBelow`
      font-size: ${font.h1Tablet};
    `}
    ${media.mobileOnly`
      font-size: ${font.h1Mobile};
    `}
  }

  h2 {
    font-size: ${font.h2};
    line-height: 1.25;
    ${media.smallDesktopAndBelow`
      font-size: ${font.h2Tablet};
    `}
    ${media.mobileOnly`
      font-size: ${font.h2Mobile};
    `}
  }

  h1{
    @supports (mix-blend-mode: difference) {
      color: white;
    }
  }

  .intro {
    position: absolute;
    top: 2rem;
    z-index: 4;
    ${media.smallDesktopAndBelow`
      top: 20px;
    `}
  }

  .exhibition .image-block {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .exhibition .layout .carousel,
  .exhibition .layout .video,
  .exhibition .layout > .image-block + p,
  .exhibition .layout > .video + p,
  .exhibition .layout > .carousel + p,
  .exhibition .layout > .image-block {
    margin-top: 5rem;
    ${media.smallDesktopAndBelow`
      margin-top: 50px;
    `}
  }

  .exhibition > .image-block:nth-child(1) {
    width: 57rem;
    ${media.smallDesktopAndBelow`
      width: 570px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: calc(100% - 140px);
    `}
    ${media.tabletPortraitAndBelow`
      width: calc(100% - 120px);
    `}
  }

  .exhibition .layout > .image-block.portrait{
    width: 50rem;
    ${media.smallDesktopAndBelow`
      width: 500px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: calc(100% - 100px);
    `}
    ${media.tabletPortraitAndBelow`
      width: calc(100% - 80px);
    `}
  }

  .exhibition > .image-block:nth-child(1) + section,
  .exhibition > .image-block:nth-child(1) + h2{
    margin-top: 5rem;
    ${media.smallDesktopAndBelow`
      margin-top: 50px;
    `}
  }

  .exhibition .artists,
  .intro,
  h2,
  .exhibition .date-wrapper {
    width: 71rem;
    margin-left: auto;
    margin-right: auto;
    ${media.smallDesktopAndBelow`
      width: 710px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: calc(100% - 250px);
    `}
    ${media.tabletPortraitAndBelow`
      width: calc(100% - 70px);
    `}
    ${media.mobileOnly`
      width: 100%;
    `}
  }

  .exhibition .date-wrapper span,
  .exhibition .download,
  .exhibition .artists span {
    font-size: ${font.p};
    line-height: 1.45;
    ${media.smallDesktopAndBelow`
      font-size: ${font.pTablet};
    `}
    ${media.mobileOnly`
      font-size: ${font.pMobile};
    `}
  }

  .exhibition .date-wrapper,
  .exhibition h2 + .artists {
    margin-top: 2.5rem;
    ${media.smallDesktopAndBelow`
      margin-top: 25px;
    `}
  }

  .exhibition .layout {
    margin-top: 2.5rem;
    ${media.smallDesktopAndBelow`
      margin-top: 25px;
    `}
  }

  .exhibition .artists {
    display: flex;
    flex-flow: row wrap;
  }

  .exhibition .artists span {
    display: inline-flex;
    position: relative;
  }

  .exhibition .artist a {
    ${underline("transparent", "black", true)};
    display: inline-block;
  }

  .exhibition .and {
    padding-left: 5px;
    padding-right: 5px;
  }

  .exhibition .comma {
    padding-right: 5px;
  }

  .exhibition .artists .emoji {
    display: none;
  }

  .exhibition .download {
    display: block;
    pointer-events: auto;
  }

  .caption {
    font-size: ${font.p};
    line-height: 1.33;
    margin-top: 1rem;
    text-align: left;
    ${media.smallDesktopAndBelow`
      font-size: ${font.pTablet};
      margin-top: 10px;
    `}
    ${media.mobileOnly`
      font-size: ${font.pMobile};
      margin-top: 6px;
    `}
  }

  .caption a {
    ${underline("transparent", "black", false)};
  }
`;

export default ExhibitionStyles;
