import React, { useRef, useState, useEffect } from "react";
import WithLightboxStyles from "./withLightbox.styled";
import Close from "../Close";
import { useAppStore } from "../../stores/AppStore";
import useKeyPress from "../../lib/useKeyPress";

function WithLightbox({ location, history, Component }) {
  const escPress = useKeyPress("Escape");
  const [
    { levelActive, duration, isBodyLocked },
    { setActiveCategory, setSavedLevel2State, setLevelActive, setIsBodyLocked }
  ] = useAppStore();
  const lookbookCloseTimer = useRef();

  const reset = () => {
    clearTimeout(lookbookCloseTimer.current);
    lookbookCloseTimer.current = setTimeout(() => {
      setActiveCategory();
      setSavedLevel2State({});
      setLevelActive(1);
    }, duration);
  };

  useEffect(() => {
    setIsBodyLocked(true);
  }, []);

  useEffect(() => {
    if (escPress) {
      reset();
      history.push("/");
    }
  }, [escPress]);

  return (
    <WithLightboxStyles>
      <section className="layer">
        <Component history={history} location={location} />
      </section>
    </WithLightboxStyles>
  );
}

export default WithLightbox;
