import React, { useState, useEffect } from 'react';

require('intersection-observer');

function useIntersectionObserver(props) {
  const {
    root, elem, config, isOnce
  } = props;
  if (!config) {
    throw new Error('intersection observer settings were not defined');
  }

  let observer;
  let disabled = false;

  const settings = root ? { ...config, ...root } : config;
  const [isActive, setIsActive] = useState(false);

  const makeActive = () => {
    setIsActive(true);
  };

  const makeInactive = () => {
    setIsActive(false);
  };

  const onIntersection = entries => {
    entries.forEach(entry => {
      if (disabled) {
        return;
      }
      if (entry.isIntersecting) {
        if (!isActive) {
          makeActive();
          if (isOnce) {
            removeObserver();
          }
        }
        return;
      }
      makeInactive();
    });
  };

  const createObserver = () => {
    if (!elem || !elem.current || observer) {
      return;
    }
    observer = new IntersectionObserver(entries => {
      onIntersection(entries);
    }, settings);

    observer.observe(elem.current);
  };

  useEffect(() => {
    createObserver();
    return () => {
      removeObserver();
    };
  }, []);

  const removeObserver = () => {
    if (observer && elem.current) {
      observer.unobserve(elem.current);
    }
    observer = undefined;
    disabled = true;
  };

  return isActive;
}

export default useIntersectionObserver;
