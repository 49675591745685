import React, { useContext, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "../Styles";
import Meta from "../Meta";
import Store from "../../stores";
import Loader from "../Loader";
import { initialState as appStore } from "../../stores/AppStore";

// Build initial state
const initialState = {
  ...appStore
};

const ThemeWrapper = props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

const Page = props => (
  <ThemeWrapper>
    <Store.Provider initialState={initialState}>
      <GlobalStyles />
      {props.children}
    </Store.Provider>
  </ThemeWrapper>
);

export default Page;
