import React, { Suspense, useState } from "react";
import PreviewStyles from "./Preview.styled";
import { useAppStore } from "../../stores/AppStore";
import ImageBlock from "../ImageBlock";
import useWindowSize from "../../lib/useWindowSize";
import { animated, useSpring } from "react-spring";

function Preview({ isVisible, isHardCut }) {
  const [{ previewedExhibition }] = useAppStore();
  const props = useSpring({
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({ opacity: 0 });
    },
    from: { opacity: 1 }
  });

  if (window.innerWidth < 768 || !previewedExhibition) {
    return <PreviewStyles className="preview" />;
  }

  const { featuredImage } = previewedExhibition;
  const opacity = { opacity: isVisible ? 1 : 0 };
  return (
    <PreviewStyles
      className="preview"
      style={isVisible && !isHardCut ? props : opacity}
    >
      <ImageBlock
        noLazyLoad={true}
        noAnimated={true}
        imageImager={featuredImage[0].imageImager}
        title={featuredImage[0].title}
        name="image-block"
      />
    </PreviewStyles>
  );
}

export default Preview;
