import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import rawMarkup from "../../lib/rawMarkup";

function ExhibitionListArtists({ artists }) {
  return artists.map((artist, i) => {
    if (artists.length >= 2 && i === artists.length - 1) {
      return (
        <Fragment key={i}>
          <span className="and">&amp;</span>
          <span
            className="artist"
            dangerouslySetInnerHTML={artist && rawMarkup(artist.content)}
          />
          <span
            className="emoji"
            dangerouslySetInnerHTML={rawMarkup(
              Array(artists.length)
                .fill(`&#128118;`)
                .join("")
            )}
          />
        </Fragment>
      );
    }
    if (artists.length >= 2 && i > 0 && i < artists.length - 1) {
      return (
        <Fragment key={i}>
          <span className="comma">,</span>
          <span
            className="artist"
            dangerouslySetInnerHTML={artist && rawMarkup(artist.content)}
          />
        </Fragment>
      );
    } else {
      return (
        <span
          key={i}
          className="artist"
          dangerouslySetInnerHTML={artist && rawMarkup(artist.content)}
        />
      );
    }
  });
}

export default React.memo(ExhibitionListArtists);
