// ****************************************/
// Abuot
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const AboutStyles = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  width: calc(100% - 25.5rem);
  padding: 10.5rem 2rem 2rem calc(2rem + 25.5rem);
  /* padding: 1rem 2rem 2rem calc(2rem + 25.5rem); */
  ${media.smallDesktopAndBelow`
    padding: 105px calc(10px + 35px) 20px calc(10px + 35px);
    /* padding: 10px calc(10px + 35px) 20px calc(10px + 35px); */
    width: calc(100% - 180px);
  `}
  ${media.tabletLandscapeAndBelow`
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    width: calc(100% - 35px);
  `}

  .about-nav {
    position: fixed;
    top: 0;
    background: inherit;
    z-index: 2;
    left: 50%;
    width: 73rem;
    padding: 2.1rem 3rem 1.4rem 3rem;
    transform: translateX(-50%);
    /* opacity: 0;
    pointer-events: none; */
    ${media.smallDesktopAndBelow`
      left: 0;
      transform: initial;
      padding: 21px 20px 14px 20px;
      width: calc(100% - 180px);
    `}
    ${media.tabletLandscapeAndBelow`
      padding: 17px 32px 15px 32px;
      width: calc(100% - 70px);
      left: 7px;
    `}
  }

  .about-nav > section {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    ${media.smallDesktopAndBelow`
      width: 710px;
      margin: auto;
      padding: 0 45px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: 100%;
      padding: 0;
    `}
  }

  .about-nav .indicator.is-active {
    width: initial;
    height: initial;
  }

  .about-nav .indicator.is-inactive {
    width: 0;
    height: 0;
  }

  .about-nav button {
    position: relative;
    outline: 0;
    border: 0;
    background: transparent;
    display: flex;
    font-size: ${font.h1};
    ${media.tabletLandscapeAndBelow`
      font-size: ${font.h1Tablet};
    `}
    ${media.smallDesktopAndBelow`
      font-size: ${font.h1Mobile};
    `}
  }

  .about-nav button span {
    display: inline-block;
    overflow-x: hidden;
  }

  .about-nav button + button {
    margin-top: 5px;
  }

  .about-nav button:focus {
    outline: none;
  }

  .layout, .social {
    width: 71rem;
    margin: auto;
    padding: 0.8rem 2rem;
    ${media.smallDesktopAndBelow`
      width: 710px;
      padding: 8px 20px;      
    `}
    ${media.tabletLandscapeAndBelow`
      width: 100%;
    `}
  }

  h2 a:hover, p a:hover {
    ${underline("transparent", "black", false)};
  }

  .associations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    align-items: center;
    width: 71rem;
    padding: 5rem 2rem;
    margin: auto;
    ${media.smallDesktopAndBelow`
      width: 710px;
      grid-column-gap: 50px;
      grid-column-gap: 50px;
      padding-top: 30px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: 100%;
    `}
    ${media.mobileOnly`
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 25px;
    `}
  }

  .associations img {
    max-width: 10rem;
    max-height: 10rem;
    object-fit: contain;
    ${media.smallDesktopAndBelow`
      max-width: 100%;
      max-height: 30px;
    `}
    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
    }
  }

  .associations a {
    display: block;
    text-align: center;
  }

  .associations picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social span {
    display: inline-block;
    font-size: ${font.p};
    line-height: 1.45;
    ${media.smallDesktopAndBelow`
      font-size: ${font.pTablet};
    `}
    ${media.mobileOnly`
      font-size: ${font.pMobile};
    `}
  }
  
  .social span + span {
    margin-left: 0.8rem;
    ${media.smallDesktopAndBelow`
      margin-left: 8px;
    `}
  }

  /* .shop-wrapper {
    display: none;
  } */

`;

export default AboutStyles;
