// ****************************************/
// Close
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const CloseStyles = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  width: 25.5rem;
  height: 50vh;
  display: flex;
  z-index: 9;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  ${media.smallDesktopAndBelow`
    width: 180px;
    padding: 20px;
  `}
  ${media.tabletLandscapeAndBelow`
    width: 35px;
    padding: 0;
  `}

  button,
  a {
    background: transparent;
    outline: 0;
    border: 0;
    transition: transform 0.25s ease-in-out;
    ${media.tabletLandscapeAndBelow`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 12px;
    `}
    ${media.tabletPortraitAndBelow`
      padding-top: 10px;
    `}
  }

  button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  button:hover {
    transform: scale(0.95);
  }

  .close-wrapper {
    position: relative;
    display: block;
    width: 21.5rem;
    height: 21.5rem;
    ${media.smallDesktopAndBelow`
      width: 150px;
      height: 150px;
    `}
    ${media.tabletLandscapeAndBelow`
      width: 25px;
      height: 25px
    `}
  }

  .close-wrapper span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    background: black;
    transform: translate(-50%, -50%);
  }

  &.is-inverted .close-wrapper span {
    background: white;
  }

  .close-wrapper > span:nth-child(2) {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  /* svg {
    display: block;
    width: 150px;
    height: 150px;
    pointer-events: none;
    ${media.tabletLandscapeAndBelow`
      width: 13px;
      height: 13px;
    `}
    ${media.mobileOnly`
      width: 21px;
      height: 21px;
    `}
  } */

  &.close-btn .back-svg {
    display: none;
  }

  &.close-btn .close-svg {
    display: block;
  }

  &.back-btn .close-svg {
    display: none;
  }

  &.back-btn .back-svg {
    display: block;
  }
`;

export default CloseStyles;
