import React, { useRef, useEffect, useState } from "react";
import LogoAnimator from "../LogoAnimator";
import Exhibition from "../Exhibition";
import rawMarkup from "../../lib/rawMarkup";
import useWindowSize from "../../lib/useWindowSize";

function InfiniteScrollLoop({
  showPrevious,
  currentExhibitions,
  exhibitions,
  isFrench
}) {
  const ref = useRef();
  const [rectTop, setRectTop] = useState(0);
  const { viewportW, viewportH } = useWindowSize();

  const onScroll = () => {
    const scrollTop =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollTop >= rectTop) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (ref.current) {
      const scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      setRectTop(ref.current.getBoundingClientRect().top + scrollTop);
    }
  }, [viewportW]);

  useEffect(() => {
    if (rectTop) {
      document.addEventListener("scroll", onScroll, {
        capture: true,
        passive: true
      });
    }
    return () => {
      if (rectTop) {
        document.removeEventListener("scroll", onScroll, {
          capture: true,
          passive: true
        });
      }
    };
  }, [rectTop]);

  return (
    <section className="loop" ref={ref}>
      {currentExhibitions.length
        ? currentExhibitions.map((exhibition, i) => (
            <Exhibition
              title={exhibition.title}
              subtitle={exhibition.subtitle}
              id={exhibition.id}
              uri={exhibition.uri.split("/")[1]}
              featuredImage={exhibition.featuredImage}
              date={exhibition.date}
              artists={exhibition.artists}
              layout={exhibition.layout}
              exhibitionDate={exhibition.date}
              key={i}
              hideEndDate={exhibition.hideEndDate}
            />
          ))
        : null}
      <LogoAnimator isAlwaysActive={!currentExhibitions.length} />
      {!currentExhibitions.length && showPrevious ? (
        <section className="previous-exhibition-container">
          <span
            className="previous-exhibition-mark"
            dangerouslySetInnerHTML={rawMarkup(
              !isFrench
                ? "Previous Exhibitions"
                : "Expositions pr&eacute;c&eacute;dentes"
            )}
          />
        </section>
      ) : null}
      {!currentExhibitions.length && exhibitions.length ? (
        <Exhibition
          title={exhibitions[0].title}
          subtitle={exhibitions[0].subtitle}
          id={exhibitions[0].id}
          uri={exhibitions[0].uri.split("/")[1]}
          featuredImage={exhibitions[0].featuredImage}
          date={exhibitions[0].date}
          artists={exhibitions[0].artists}
          layout={exhibitions[0].layout}
          exhibitionDate={exhibitions[0].date}
          hideEndDate={exhibitions[0].hideEndDate}
        />
      ) : null}
    </section>
  );
}

export default InfiniteScrollLoop;
