import React, { useState, useEffect } from "react";
import ExhibitionStyles from "./Exhibition.styled";
import PropTypes from "prop-types";
import rawMarkup from "../../lib/rawMarkup";
import ImageBlock from "../ImageBlock";
import Layout from "../Layout";
import ExhibitionListArtists from "../ExhibitionListArtists";
import moment from "moment";
import "moment/locale/fr";
import { useAppStore } from "../../stores/AppStore";
import { Capitalise } from "../../lib/Utils";
import { useInView } from "react-intersection-observer";
import useWindowSize from "../../lib/useWindowSize";

function Exhibition({
  title,
  subtitle,
  id,
  uri,
  featuredImage,
  date,
  artists,
  layout,
  exhibitionDate,
  hideEndDate
}) {
  const [{ isFrench }, { setActiveExhibitionId }] = useAppStore();
  const today = isFrench ? "Aujourd'hui Ã " : "Today until";
  const save = isFrench ? "Ajouter Au Calendrier" : "Add To Calendar";
  const { viewportH } = useWindowSize();

  const { start, end, isOnGoing, isFuture } = exhibitionDate;

  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: `0px 0px -${viewportH - 40}px 0px`,
    triggerOnce: false
  });

  if (isFrench) {
    moment.locale("fr");
  } else {
    moment.locale("en-gb");
  }
  const calcTime = date => {
    const day = Capitalise(moment(date).format("Do"));
    const month = moment(date).format("MMMM");
    const yearTime = moment(date).format("YYYY, h:mma");
    return `${day} ${month} ${yearTime}`;
  };

  const calcShortEndTime = date => moment(date).format("h:mma");

  const getTime = () => {
    // debugger;
    const endTime = calcTime(end.date);
    if (isOnGoing) {
      return `${today}&nbsp;${calcShortEndTime(
        end.date
      )}&nbsp;&ndash;<br>${endTime}`;
    }
    const startTime = calcTime(start.date);
    if (!hideEndDate) {
      return `${startTime}&nbsp;&ndash;<br>${endTime}`;
    }
    return `${startTime}`;
  };

  useEffect(() => {
    if (inView) {
      setActiveExhibitionId(id);
    }
  }, [inView]);

  return (
    <ExhibitionStyles className="exhibition-wrapper" ref={ref}>
      <section className="intro">
        <section className="intro-wrap">
          <h1>{title}</h1>
        </section>
      </section>
      <section className="exhibition">
        <ImageBlock
          noLazyLoad={true}
          noAnimated={true}
          imageImager={featuredImage[0].imageImager}
          title={featuredImage[0].title}
          name="image-block"
        />
        {subtitle ? (
          <h2
            className="subtitle"
            dangerouslySetInnerHTML={subtitle && rawMarkup(subtitle.content)}
          />
        ) : null}
        {artists.length ? (
          <section className="artists">
            <span className="subtitle">
              {isFrench ? "Avec" : "Featuring"}&nbsp;
            </span>
            <ExhibitionListArtists artists={artists} />
          </section>
        ) : null}
        <section className="date-wrapper">
          <span
            className="dates"
            dangerouslySetInnerHTML={{ __html: getTime() }}
          />
          {isOnGoing || isFuture ? (
            <a
              className="download"
              href={`event/${uri}`}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{
                __html: `&#43;&nbsp;${save}`
              }}
            />
          ) : null}
        </section>
        <Layout layout={layout} />
      </section>
    </ExhibitionStyles>
  );
}

Exhibition.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.object,
  featuredImage: PropTypes.array,
  date: PropTypes.object,
  artists: PropTypes.array
};

export default Exhibition;
