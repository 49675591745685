import React, { useRef, useEffect, Suspense } from "react";
import { useInView } from "react-intersection-observer";
const Image = React.lazy(() => import("../Image"));
import ImageBlockStyles from "./ImageBlock.styled";
import rawMarkup from "../../lib/rawMarkup";

const ImageBlock = ({
  imageImager,
  imageCaption,
  title,
  hasMargin,
  position,
  name,
  noAnimation,
  noLazyLoad,
  style
}) => {
  const { width, height } = imageImager[0];

  const [ref, inView] = !noLazyLoad
    ? useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
      })
    : [undefined, true];

  return (
    <ImageBlockStyles
      ref={ref}
      className={`image-block ${hasMargin ? "has-margin" : ""} ${position ||
        ""} ${name || ""} ${
        parseInt(width, 10) >= parseInt(height, 10) ? "landscape" : "portrait"
      }`}
      style={style || {}}
    >
      <section>
        <>
          <Suspense fallback={null}>
            <Image
              noLazyLoad={inView}
              isAnimated={noAnimation}
              alt={title}
              src={imageImager[0].url}
              srcMobile={imageImager[1].url}
              srcWebP={imageImager[2].url}
              srcWebPMobile={imageImager[3].url}
              width={width}
              height={height}
            />
          </Suspense>
          {imageCaption ? (
            <p
              className="caption"
              dangerouslySetInnerHTML={
                imageCaption && rawMarkup(imageCaption.content)
              }
            />
          ) : null}
        </>
      </section>
    </ImageBlockStyles>
  );
};

export default React.memo(ImageBlock);
