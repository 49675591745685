// ****************************************/
// InfiniteScroll
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const InfiniteScrollStyles = styled.section`
  position: relative;

  .previous-exhibition-container {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: ${font.p};
    background: linear-gradient(
      to bottom,
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 0)
    );
    ${media.smallDesktopAndBelow`
      font-size: ${font.pTablet};
    `}
    ${media.mobileOnly`
      font-size: ${font.pMobile};
    `}
  }

  .previous-exhibition-mark {
    position: relative;
    padding: 6rem;
    display: block;
    ${media.smallDesktopAndBelow`
      padding: 60px;
    `}
  }

  .sentinel {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    pointer-events: none;
  }
`;

export default InfiniteScrollStyles;
