import React, { useEffect, useState, useRef, Suspense } from "react";
import { Route } from "react-router-dom";
import Home from "../../pages/Home";
import About from "../../pages/About";
import Logo from "../../pages/Logo";
import Exhibitions from "../../pages/Exhibitions";
import TransitionFadeStyles from "./TransitionFade.styled";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import useWindowSize from "../../lib/useWindowSize";
import { useAppStore } from "../../stores/AppStore";
import Close from "../Close";
import Preview from "../Preview";
const FaviconFlag = React.lazy(() => import("../FaviconFlag"));

const routes = [
  { path: "/about", exact: true, Component: About },
  { path: "/logo", exact: true, Component: Logo },
  {
    path: "/exhibitions/:id",
    exact: false,
    Component: function() {
      return null;
    }
  },
  { path: "/exhibitions", exact: true, Component: Exhibitions },
  {
    path: "/",
    exact: false,
    Component: function() {
      return null;
    }
  }
];

function TransitionFade({ location, history }) {
  const [{ scrollTop }, { setIsBodyLocked, setScrollTop }] = useAppStore();
  const [layerScrollTop, setLayerScrollTop] = useState(0);
  const prevScrollPos = useRef(0);
  const isHome =
    location.pathname === "/" ||
    location.pathname.substring(0, 13) === "/exhibitions/";

  const isPreviouslyHome =
    history.location.pathname === "/" ||
    history.location.pathname.substring(0, 13) === "/exhibitions/";

  const isExhibitions = location.pathname.substring(0, 13) === "/exhibitions/";

  useEffect(() => {
    if (history.action === "POP" && !isHome) {
      setIsBodyLocked(true);
      window.scrollTo(0, 0);
      setLayerScrollTop(0);
    }
  }, [location]);

  return (
    <TransitionFadeStyles className="page-wrap">
      <section
        className={`container ${
          !location.state || !location.state.fromLeft
            ? "from-right"
            : "from-left"
        }`}
        style={{ top: `${layerScrollTop}px` }}
      >
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match }) => (
              <CSSTransition
                in={match !== null}
                timeout={700}
                classNames={"page"}
                unmountOnExit
                onEntered={() => {
                  if (isPreviouslyHome) {
                    // setIsBodyLocked(true);
                    prevScrollPos.current =
                      window.scrollY ||
                      document.documentElement.scrollTop ||
                      document.body.scrollTop;
                    setLayerScrollTop(0);
                  }
                }}
                onEntering={() => {
                  // if (isPreviouslyHome) {
                  //   setLayerScrollTop(
                  //     window.scrollY ||
                  //       document.documentElement.scrollTop ||
                  //       document.body.scrollTop
                  //   );
                  // }
                }}
                onExit={() => {
                  // if (isHome) {
                  //   setIsBodyLocked(false);
                  //   window.scrollTo(0, prevScrollPos.current);
                  //   setLayerScrollTop(prevScrollPos.current);
                  // }
                }}
                onExiting={() => {
                  // if (isHome) {
                  //   setIsBodyLocked(false);
                  //   window.scrollTo(0, prevScrollPos.current);
                  //   setLayerScrollTop(prevScrollPos.current);
                  //   return;
                  // }
                  // setLayerScrollTop(
                  //   window.scrollY ||
                  //     document.documentElement.scrollTop ||
                  //     document.body.scrollTop
                  // );
                }}
                onExited={() => {
                  if (isHome) {
                    setIsBodyLocked(false);
                    if (isExhibitions) {
                      window.scrollTo(0, 0);
                      setScrollTop(0);
                      return;
                    }

                    setScrollTop(0);
                    window.requestAnimationFrame(() => {
                      window.scrollTo(0, scrollTop);
                    });
                  }
                }}
              >
                <section className="page">
                  <Component history={history} location={location} />
                </section>
              </CSSTransition>
            )}
          </Route>
        ))}
      </section>
      <Home isHome={isHome} />
      <Suspense fallback={null}>
        <FaviconFlag location={location} />
      </Suspense>
      <Close location={location} history={history} />
    </TransitionFadeStyles>
  );
}

export default TransitionFade;
