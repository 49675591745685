// ****************************************/
// Layout
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const LayoutStyles = styled.section`
  h2,
  p,
  a {
    font-variant-numeric: normal;
  }

  h2,
  & > p {
    width: 71rem;
    margin: auto;
    ${media.smallDesktopAndBelow`
      width: 710px;
    `}
    ${media.tabletLandscapeAndBelow`
     width: 100%;
    `}
  }

  h2 {
    font-size: ${font.h1};
    line-height: 1.25;
    ${media.tabletLandscapeAndBelow`
      font-size: ${font.h1Tablet};
    `}
    ${media.tabletLandscapeAndBelow`
      font-size: ${font.h1Mobile};
    `}
  }

  h2 + p,
  h2 + h2 {
    margin-top: 2rem;
    ${media.smallDesktopAndBelow`
      margin-top: 20px;
    `}
  }

  & > a {
    ${underline("transparent", "black", true)};
  }

  & > p {
    font-size: ${font.p};
    line-height: 1.45;
    ${media.smallDesktopAndBelow`
      font-size: ${font.pMobile};
    `}
  }

  p + ul,
  p + ol,
  ul + p,
  ul + ul,
  ol + ol,
  ul + ol,
  ol + ul,
  ol + p,
  p + p {
    margin-top: 0.8rem;
    ${media.smallDesktopAndBelow`
      margin-top: 8px;
    `}
  }

  h2 br {
    ${media.mobileOnly`
      content: ' ';
    `}
  }

  h2 br:after {
    ${media.mobileOnly`
      content: ' ';
    `}
  }

  ul,
  ol {
    position: relative;
  }

  ol {
    padding-left: 17px;
  }

  ul {
    padding-left: 10px;
    ${media.smallDesktopAndBelow`
		  padding-left: 10px;
		`}
  }

  ul li {
    position: relative;
  }

  ul li:before {
    position: absolute;
    left: -10px;
    top: 50%;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background: black;
    display: block;
    transform: translateY(calc(-50% - 1px));
    content: "";
  }
`;

export default LayoutStyles;
