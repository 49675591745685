import React, { useEffect, Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import rawMarkup from "../../lib/rawMarkup";
import { useAppStore } from "../../stores/AppStore";
import moment from "moment";
import "moment/locale/fr";
import Ticker from "react-ticker";
import useWindowSize from "../../lib/useWindowSize";
import ExhibitionListArtists from "../ExhibitionListArtists";
import { Link } from "react-router-dom";

function ExhibitionListItem({ num, style, isLoading, item, toggleItemActive }) {
  const [{ activeExhibitionId, isFrench }] = useAppStore();
  const { viewportW } = useWindowSize();
  const [isArtistsMoving, setIsArtistsMoving] = useState(
    viewportW < 768 && item && item.artists.length >= 2
  );
  const [isTitleMoving, setIsTitleMoving] = useState(
    viewportW < 768 && item && item.title.length >= 15
  );
  const today = isFrench ? "Aujourd'hui à" : "Today until";

  const setActive = () => {
    if (!item) {
      return;
    }
    if (item.artists.length >= 2) {
      setIsArtistsMoving(true);
    }
    if (item.title.length >= 30) {
      setIsTitleMoving(true);
    }
    toggleItemActive(num);
  };

  const setInactive = () => {
    if (!item) {
      return;
    }
    if (item.artists.length >= 2) {
      setIsArtistsMoving(false);
    }
    if (item.title.length >= 30) {
      setIsTitleMoving(false);
    }
    toggleItemActive();
  };
  const onMouseEnter = useCallback(e => setActive(), []);
  const onMouseLeave = useCallback(e => setInactive(), []);

  if (!item) {
    return null;
  }
  const { title, id, subtitle, artists, date, uri } = item;
  const { start, end, isOnGoing } = date;

  if (isFrench) {
    moment.locale("fr");
  } else {
    moment.locale("en-gb");
  }
  const calcStartTime = date => moment(date).format("DD.MM");
  const calcEndTime = date => moment(date).format("DD.MM.YYYY");

  const getTime = () => {
    if (isOnGoing) {
      return `${today} ${calcEndTime(end.date)}`;
    }
    const startTime = calcStartTime(start.date);
    const endTime = calcEndTime(end.date);
    const clonedStart = startTime.slice(0, 5);
    const clonedEnd = endTime.slice(0, 5);
    if (clonedStart === clonedEnd) {
      return endTime;
    }
    return `${startTime}&ndash;${endTime}`;
  };

  return (
    <section className="exhibition-list-item" style={style}>
      <Link
        to={{
          pathname: `/exhibitions/${uri.split("/")[1]}`,
          state: {
            fromLeft: false
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-id={num}
      >
        <span className="title">
          <Ticker speed={3.5} move={isTitleMoving} mode={"await"}>
            {({ index }) => (
              <section className="wrapper" key={index}>
                <span
                  dangerouslySetInnerHTML={rawMarkup(
                    `${id === activeExhibitionId ? "&rarr;&nbsp;" : ""}${
                      item.title
                    }`
                  )}
                />
              </section>
            )}
          </Ticker>
        </span>
        <span
          className={`artists ${artists.length > 2 ? "is-large" : "is-small"}`}
        >
          <Ticker speed={3.5} move={isArtistsMoving} mode={"await"}>
            {({ index }) => (
              <section className="wrapper">
                <ExhibitionListArtists artists={artists} />
              </section>
            )}
          </Ticker>
        </span>
        <span
          className="dates"
          dangerouslySetInnerHTML={{ __html: getTime() }}
        />
      </Link>
    </section>
  );
}

export default React.memo(ExhibitionListItem);
