import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useSprings, useTransition, animated } from "react-spring";
import PropTypes from "prop-types";

import useWindowSize from "../../lib/useWindowSize";
import { useAppStore } from "../../stores/AppStore";
import useKeyPress from "../../lib/useKeyPress";
import CarouselSlide from "./CarouselSlide";
import { ArrayRotate } from "../../lib/Utils";

function CarouselController({ slides, setIndex }) {
  const index = useRef(0);
  const { viewportW } = useWindowSize();
  const [{ easing, duration }] = useAppStore();
  const { length } = slides;
  const supportsWebP = useRef();

  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");
  const arr = [...Array(length).keys()];

  const shouldLazyLoad = i => {
    const threshold = 3;

    const rotatedArray = ArrayRotate(arr.slice(0), index.current);
    const min = rotatedArray.slice(0, threshold);
    const max = rotatedArray.slice(-threshold);
    const lazyLoadArray = [...min, ...max];

    const display = lazyLoadArray.includes(i) ? "flex" : "none";
    return display;
  };

  const [props, set] = useSprings(slides.length, i => ({
    display: shouldLazyLoad(i),
    opacity: i === index.current ? 1 : 0,
    zIndex: i === index.current ? 2 : 1,
    transform: `scale(${i === index.current ? 1 : 0.9})`,
    config: { duration: duration / 2, easing }
  }));

  const previous = () => {
    const newIndex = (index.current - 1 + length) % length;
    index.current = newIndex;
    setIndex(newIndex);
    set(i => {
      return {
        display: shouldLazyLoad(i),
        opacity: i === index.current ? 1 : 0,
        zIndex: i === index.current ? 2 : 1,
        transform: `scale(${i === index.current ? 1 : 0.9})`
      };
    });
  };

  const next = () => {
    const newIndex = (index.current + 1 + length) % length;
    index.current = newIndex;
    setIndex(newIndex);
    set(i => {
      return {
        display: shouldLazyLoad(i),
        opacity: i === index.current ? 1 : 0,
        zIndex: i === index.current ? 2 : 1,
        transform: `scale(${i === index.current ? 1 : 0.9})`
      };
    });
  };

  const onClick = e => {
    e.preventDefault();
    const { clientX } = e;
    const shoudMoveRight = clientX >= viewportW / 2;
    if (shoudMoveRight) {
      next();
      return;
    }
    previous();
  };

  const checkWebPSupport = async () => {
    if (!self.createImageBitmap) return false;

    const webpData =
      "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(
      () => true,
      () => false
    );
  };

  const preload = slide => {
    if (!slide) {
      return;
    }
    const images = [];
    slide.map(item => {
      if (item.image) {
        const src = supportsWebP.current
          ? item.image[0].image.imageImager[2]
          : item.image[0].image.imageImager[1];
        const image = new Image();
        image.src = src;
        images.push(image);
      }
    });
  };

  const preloadSlides = async () => {
    if (supportsWebP.current === undefined) {
      supportsWebP.current = await checkWebPSupport();
    }
    // preload(slides[index + 1]);
    // preload(slides[index + 2]);
    // preload(slides[index + 3]);
  };

  useEffect(() => {
    preloadSlides();
  }, [index]);

  useEffect(() => {
    if (leftPress) {
      previous();
    }
    if (rightPress) {
      next();
    }
  }, [leftPress, rightPress]);

  return (
    <>
      {props.map(({ display, zIndex, opacity, transform }, i) => {
        const { title, video, imageImager } = slides[i][0];
        return (
          <animated.section
            className="slide"
            key={i}
            style={{ display, zIndex, opacity, transform }}
            onClick={onClick}
          >
            <CarouselSlide
              title={title}
              video={video}
              imageImager={imageImager}
            />
          </animated.section>
        );
      })}
    </>
  );
}

CarouselController.propTypes = {
  slides: PropTypes.array,
  index: PropTypes.number,
  setIndex: PropTypes.func,
  Slide: PropTypes.object,
  style: PropTypes.object
};

export default CarouselController;
