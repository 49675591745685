// ****************************************/
// Home
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";
import { animated } from "react-spring";

const HomeStyles = styled(animated.section)`
  position: relative;
  width: 100%;

  .previous-exhibition-container {
    margin-bottom: 10rem;
    ${media.smallDesktopAndBelow`
      margin-bottom: 100px;
    `}
    ${media.tabletPortraitAndBelow`
      margin-bottom: 80px;
    `}
  }

  section + .infinite-scroll .previous-exhibition-container {
    margin: 10rem 0;
    ${media.smallDesktopAndBelow`
      margin: 100px 0;
    `}
    ${media.tabletPortraitAndBelow`
      margin: 80px 0;
    `}
  }
  /* 
  .infinite-scroll > .exhibition:nth-child(n + 2):before {
    position: absolute;
    top: -8rem;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    right: 50%;
    width: 100vw;
    height: 1px;
    background: red;
    display: block;
    content: "";
  } */
`;

export default HomeStyles;
