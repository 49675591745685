import React, { useEffect, useState } from "react";
import CloseStyles from "./Close.styled";
import { useAppStore } from "../../stores/AppStore";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { useWindowSize } from "../../lib/useWindowSize";

function Close({ location, history }) {
  const [
    { easing, duration, isInverted },
    { setIsFooterActive, setScrollTop }
  ] = useAppStore();
  const isHome =
    location.pathname === "/" ||
    location.pathname.substring(0, 13) === "/exhibitions/";
  const [props, set] = useSpring(() => ({
    transform: "rotate(0deg)",
    config: { easing, duration: 250 }
  }));
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    set({
      transform: !isHome ? "rotate(45deg)" : "rotate(0deg)"
    });
    setIsActive(location.pathname !== "/");
  }, [location]);

  const onClick = () => {
    if (
      location.pathname !== "/about" &&
      location.pathname !== "/exhibitions"
    ) {
      setScrollTop(window.pageYOffset);
      history.push("/exhibitions", { fromLeft: false });
      return;
    }
    if (!isHome) {
      history.push("/", { fromLeft: location.pathname !== "/exhibitions" });
      return;
    }
  };

  const onMouseEnter = () => {
    if (location.pathname === "/") {
      setIsFooterActive(true);
    }
  };

  const onMouseLeave = () => {
    setIsFooterActive(false);
  };

  return (
    <CloseStyles className={`${isInverted ? "is-inverted" : ""}`}>
      <button
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <animated.span className="close-wrapper" style={props}>
          <span />
          <span />
        </animated.span>
      </button>
    </CloseStyles>
  );
}

export default Close;
