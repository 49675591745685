import React, { useEffect } from "react";
import { render } from "react-dom";
import Page from "./components/Page";
import Router from "./components/Router";
import { BrowserRouter } from "react-router-dom";

if (module.hot) {
  module.hot.accept();
}

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <Page>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Page>
  );
}

render(<App />, document.querySelector(".main"));
