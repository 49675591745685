import React, { useEffect, useRef, useState } from "react";
import LoaderStyles from "./Loader.styled";
import Zdog from "zdog";
import { useAppStore } from "../../stores/AppStore";

function Loader() {
  const speed = 0.03;
  const isSpinning = true;
  const illo = useRef();
  const ticker = useRef(0);
  const cycleCount = useRef(350);
  const [{ colour1, colour2 }] = useAppStore();

  function animate() {
    // apply easing to rotation
    illo.current.rotate.y += 0.01;
    illo.current.rotate.x += 0.01;
    // illo.current.rotate.y += isSpinning ? 0.03 : 0;
    // illo.current.rotate.x += isSpinning ? 0.03 : 0;
    ticker.current++;

    illo.current.updateRenderGraph();
    requestAnimationFrame(animate);
  }

  useEffect(() => {
    illo.current = new Zdog.Illustration({
      element: ".loader"
    });

    new Zdog.Box({
      addTo: illo.current,
      width: 11,
      height: 11,
      depth: 11,
      stroke: 1,
      fill: false,
      color: "#000", // default face color
      leftFace: "#000",
      rightFace: "#000",
      topFace: "#000",
      bottomFace: "#000"
    });

    animate();
  }, [colour1]);

  return (
    <LoaderStyles className="loader" width="22" height="22">
      <section />
    </LoaderStyles>
  );
}

export default Loader;
