// ****************************************/
// Typography
// ****************************************/
//
// p {
// 	font-size: ${font.p};
// 	${media.smallDesktopAndBelow`
// 		font-size: ${font.pMobile};
// 	`};
// };
//

const font = {
  family:
    '"Arsenic", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  h1: "3.0rem",
  h1Tablet: "27px",
  h1Mobile: "23px",
  h2: "2.4rem",
  h2Tablet: "24px",
  h2Mobile: "19px",
  capsXl: "17px",
  caps: "1.5rem",
  capsMobile: "15px",
  p: "1.5rem",
  pTablet: "15px",
  pMobile: "16px",
  smallCapsXl: "15px",
  smallCaps: "1.3rem",
  smallCapsMobile: "13px",
  letterSpacing: "0.07rem",
  letterSpacingMobile: "0.7px",
  letterSpacingCaps: "0.25rem",
  letterSpacingCapsMobile: "2.5px"
};

export default font;
