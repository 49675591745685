// ****************************************/
// Video
// ****************************************/

import styled from "styled-components";
import { font, media, underline } from "../Styles";

const VideoStyles = styled.section`
  .video-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default VideoStyles;
