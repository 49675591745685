import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated, interpolate, useCallback } from "react-spring";
import PropTypes from "prop-types";
import CarouselStyles from "./Carousel.styled";
import CarouselController from "./CarouselController";
import Cursor from "./Cursor";
import { ClosestLowerNumber } from "../../lib/Utils";
import useOnScreen from "../../lib/useOnScreen";
import { useAppStore } from "../../stores/AppStore";
import usePrevious from "../../lib/usePrevious";
import useClientRect from "../../lib/useClientRect";
import rawMarkup from "../../lib/rawMarkup";

function Carousel({ slides, style, initialIndex, onChange, caption }) {
  const { easing, duration } = useAppStore();
  const [index, setIndex] = useState(0);

  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    immediate: true
  }));

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration, easing }
  });

  function disableScrollIfUpDown(e) {
    if ((e.keyCode === 37 || e.keyCode === 39) && e.target === document.body) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", disableScrollIfUpDown);
    return () => {
      window.removeEventListener("keydown", disableScrollIfUpDown);
    };
  });

  const { width, height } = slides[0][0].imageImager[0];

  return (
    <CarouselStyles
      style={{
        ...props,
        paddingBottom: `${(parseInt(height, 10) / parseInt(width, 10)) * 100}%`
      }}
      className="carousel"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: [x, y] })}
    >
      <CarouselController slides={slides} style={style} setIndex={setIndex} />
      <Cursor xy={xy} />
      {caption.content ? (
        <p
          className="caption"
          dangerouslySetInnerHTML={
            caption &&
            rawMarkup(
              `${caption.content} &ndash; ${index + 1} of ${slides.length}`
            )
          }
        />
      ) : (
        <p className="caption">
          {index + 1} of {slides.length}
        </p>
      )}
      <section className="ui">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            className="left-arrow-svg"
          >
            <path fill="none" d="M0 0H15V15H0z" />
            <path
              fill="#fff"
              d="M10.92 0.04L11.84 0.96 5.3 7.5 11.84 14.04 10.92 14.96 3.46 7.5 10.92 0.04z"
            />
          </svg>
        </span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            className="right-arrow-svg"
          >
            <path fill="none" d="M0 0H15V15H0z" />
            <path
              fill="#fff"
              d="M4.08 14.96L3.16 14.04 9.7 7.5 3.16 0.96 4.08 0.04 11.54 7.5 4.08 14.96z"
            />
          </svg>
        </span>
      </section>
    </CarouselStyles>
  );
}

Carousel.propTypes = {
  initialIndex: PropTypes.number,
  slides: PropTypes.array,
  captions: PropTypes.array,
  style: PropTypes.object,
  onChange: PropTypes.func
};

export default Carousel;
