import React, { memo, Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList, areEqual } from "react-window";
import ExhibitionsListItem from "./ExhibitionListItem";
import useWindowSize from "../../lib/useWindowSize";
import Button from "../Button";
import { ParsePixels } from "../../lib/Utils";
import memoize from "memoize-one";

const Row = memo(({ data, index, style }) => {
  const { items, toggleItemActive } = data;
  return (
    <ExhibitionsListItem
      num={index}
      style={style}
      loading={index === items.length}
      item={items[index]}
      toggleItemActive={toggleItemActive}
    />
  );
}, areEqual);

const createItemData = memoize((items, toggleItemActive) => ({
  items,
  toggleItemActive
}));

function ExhibitionListContainer({
  items,
  moreItemsLoading,
  loadMore,
  hasNextPage,
  colour2,
  isFrench,
  toggleItemActive
}) {
  const { viewportW, viewportH } = useWindowSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const mobileW = 520;
  const tablet = 980;
  const tabletLandscapeW = 1024;
  const desktopW = 1280;

  const widths = {
    desktop: "25.5rem",
    tablet: "180px",
    mobile: "36px"
  };

  const heights = {
    desktop: "6.9rem",
    smallDesktop: "69px",
    tablet: "36px"
  };

  const itemHeights = {
    desktop: "36px",
    mobile: "75px"
  };

  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const onScroll = ({
    scrollDirection,
    scrollOffset,
    scrollUpdateWasRequested
  }) => {
    if (scrollOffset > 5 && !isScrolled) {
      setIsScrolled(true);
      return;
    }
    if (scrollOffset < 5) {
      setIsScrolled(false);
    }
  };

  const getWidth = () => {
    if (viewportW < tabletLandscapeW) {
      return viewportW + 20 - ParsePixels(widths.mobile) * 2;
    }
    if (viewportW < tabletLandscapeW) {
      return viewportW + 25 - ParsePixels(widths.mobile) * 2;
    }
    if (viewportW < desktopW) {
      return viewportW - ParsePixels(widths.tablet) * 2;
    }
    return viewportW - ParsePixels(widths.desktop) * 2;
  };

  const getHeight = () => {
    if (viewportW >= 1440) {
      return viewportH - ParsePixels(heights.desktop);
    }
    if (viewportW < 1440 && viewportW >= 960) {
      return viewportH - ParsePixels(heights.smallDesktop);
    }
    return viewportH - ParsePixels(heights.tablet);
  };

  const getItemSize = () => {
    if (viewportW < tabletLandscapeW) {
      return ParsePixels(itemHeights.mobile);
    }
    return ParsePixels(itemHeights.desktop);
  };

  const itemData = createItemData(items, toggleItemActive);

  return (
    <InfiniteLoader
      isItemLoaded={index => index < items.length}
      itemCount={itemCount}
      loadMoreItems={loadMore}
    >
      {({ onItemsRendered, ref }) => (
        <>
          <nav
            className={`header ${isScrolled ? "is-scrolled" : ""}`}
            style={{ background: colour2 || "initial" }}
          >
            <Button text={!isFrench ? "Exhibitions" : "Expositions"} />
            <Button text={!isFrench ? "Artists" : "Artistes"} />
            <Button text="Dates" />
          </nav>
          <FixedSizeList
            height={getHeight()}
            width={getWidth()}
            itemSize={getItemSize()}
            itemCount={itemCount}
            className="list-container"
            onItemsRendered={onItemsRendered}
            overscanCount={2}
            itemData={itemData}
            ref={ref}
          >
            {Row}
          </FixedSizeList>
          ;
        </>
      )}
    </InfiniteLoader>
  );
}

export default ExhibitionListContainer;
